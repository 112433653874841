@page {
    margin-top: 0;
    margin-bottom: 0;
}

@media print {

    html,
    body {
        background: #fff !important;
        height: 99%;
    }

    body {
        padding-top: 52px;
        padding-bottom: 52px;
    }
}